.colTextContainer,
.sfTextContainer {
  width: 80%;
  min-width: 750px;
  max-width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Poppins", sans-serif;
  position: absolute;
}

.sfTextContainer {
  display: flex;
  bottom: 40%;
  align-items: flex-end;

  .firstText {
    color: #fff;
    font-size: 32px;
    margin-bottom: 10px;
  }

  .secondText {
    font-size: 50px;
    padding-bottom: 0;
    margin-left: 12px;
    color: #f7911e;
    font-weight: 700;
  }
}

.colTextContainer {
  bottom: 34%;
  text-shadow: 0 3px 6px #00000029;
  .firstText {
    font-weight: 300;
    font-size: 32px;
    color: #fff;
  }
  .secondText {
    font-size: 70px;
    color: #fff;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&family=Roboto:wght@300;400;500;700&display=swap");

:root {
  font-family: "Roboto";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Some Addition datepicker Styles
// date range in between date dim
.rdrInRange {
  background-color: rgb(146, 193, 255) !important;
}

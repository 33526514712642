.detailsPricesContainer {
  display: flex;
  justify-content: space-around;
}

.detailsTabBtnContainer {
  display: flex;
  flex-direction: column;
  padding-right: 160px;
  flex-grow: 4;
  justify-content: space-between;

  @media (max-width: 780px) {
    padding-right: 3px;
  }
}

.detailsContainer {
  display: flex;

  justify-content: space-between;
}

.logoTimeNameContainer {
  display: flex;
  align-items: flex-start;
  img {
    width: 30px;
  }
  .time {
    margin-left: 8px;
  }
}

.hideMobile {
  @media (max-width: 740px) {
    display: none;
  }
}

.pricesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 70px;
  width: 180px;
  @media (max-width: 466px) {
    flex-direction: column;
    width: auto;
    line-height: 17px;
    padding-left: 0;
  }
}

.titleText,
.time {
  font-weight: 700;
  font-size: 16px;
  color: #231f20;
}

.secondaryText {
  font-size: 14px;
  color: #7f91a8;
  font-weight: 300;
}

.priceHeader {
  font-weight: 700;
  color: #231f20;
  font-size: 13px;
}
.priceText {
  color: #64a25c;
  font-size: 17px;
  font-weight: 700;

  @media (max-width: 780px) {
    font-size: 14px;
  }
}

.smallText {
  color: #cccccc;
  font-size: 12px;
  margin-top: 12px;
  @media (max-width: 780px) {
    font-size: 10px;
  }
}

.smallPriceText {
  color: #6e6e6e;
  font-weight: 300;
  font-size: 14px;
  @media (max-width: 780px) {
    font-size: 12px;
  }
}

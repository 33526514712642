@import "../../../common/scssStyles/variables/colors";
@import "../scssStyles/popperDesktop/mixin";

.popperPaper {
  background-color: #fff !important;
}

.container {
  width: 700px;
  padding: 20px;
  height: 350px;
}

.emptyResultContainer {
  height: 120px !important;
}

.popperHeader {
  @include popperHeader;

  .pillContainer {
    @include pillContainer;
  }

  .headText {
    @include headText;
  }
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: 23px;
  height: 100%;

  .titleText {
    font-size: 16px;
    font-weight: 700;
    padding: 10px 0;
  }

  .noResultFoundText {
    margin-top: 12px;
  }

  .cities {
    font-size: 14px;
    padding: 7px 0px;
    font-weight: 400;

    &:hover {
      color: $blue;
      cursor: pointer;
    }
  }
}

.container {
  max-width: 1200px;
  width: 80%;
  min-width: 750px;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.displaysContainer {
  display: flex;
  padding-left: 6px;
  .display {
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 3px 20px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    color: #fff;
  }
}

.searchInputsContainer {
  display: flex;
  height: 75px;
  background-color: #fff;
  border-radius: 10px;
}

.logoInputContainer {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

@import "../../../common/scssStyles/variables/colors";
@import "../scssStyles/information/mixins";

.informationContainer {
  p {
    @include title;
  }
  ul {
    @include listOfLinks;
  }

  @media (max-width: 780px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
      text-align: center;
      li {
        line-height: 50px;
        font-size: 1em;
      }
    }
  }
}

.container {
  background-color: black;
  height: 40px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  p {
    color: #fff;
  }
}

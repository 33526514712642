.container {
  @media (max-width: 941px) {
    margin-top: 20px;
  }
}

.labelText {
  font-size: 14px;
  font-weight: 300;
  @media (max-width: 941px) {
    font-size: 16px;
    font-weight: 400;
  }
}

.box {
  margin-top: 5px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  font-size: 14px;
  padding: 8px 10px;
  @media (max-width: 941px) {
    width: 100%;
  }
  .icon {
    color: #ddd;
    @media (max-width: 941px) {
      display: none;
    }
  }
}

.nav {
  border-bottom: 1px solid #e6e6e6;
  height: 60px;
}

.imgLogoutBtnContainer {
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 1020px;
  width: 90%;
  margin: auto;
  justify-content: space-between;
}

.img {
  height: 50px;
}

.flymyaLogo {
  width: 140px;
}

.inputIconContainer {
  position: relative;
  margin-top: 20px;
  & > input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #b9b9b9;
    outline: none;
    font-family: "Poppins", sans-serif;
    padding-left: 30px;
    padding-bottom: 7px;
    &::placeholder {
      font-family: "Poppins", sans-serif;
    }
  }
  & > img {
    position: absolute;
    width: 20px;
    height: 20px;
  }
}
.errorBorder {
  border-bottom: 1px solid red !important;
}
.errorText {
  margin-top: 4px;
  font-size: 12px;
  color: red;
}

.headerImgTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 160px;
    height: 44px;
  }
  h3 {
    margin-top: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: white;
  }
}

.container {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 4px;
  padding: 20px 25px;
}

.swapBtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .swapBtn {
    border: 1px solid #e6e7e8;
    background: transparent;
    padding: 7px;
    border-radius: 100%;
    .swapIcon {
      color: #707070;
    }
  }
}

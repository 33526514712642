.passengersCountContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.actionsInputContainer {
  height: 35px;
  display: flex;
  width: 120px;
  align-items: center;
  position: relative;

  button {
    background-color: transparent;
    position: absolute;
    border: none;
    padding: 2px 10px;
    &:hover {
      cursor: pointer;
    }
  }

  .removeBtn {
    border-right: 1px solid #c7c7c7;
    color: #ff5757 !important;
    &:disabled {
      opacity: 0.4;
    }
  }

  .addBtn {
    right: 0px;
    border-left: 1px solid #c7c7c7;
    color: #23af2e !important;
    &:disabled {
      opacity: 0.4;
    }
  }

  input {
    font-size: 15px;
    height: 100%;
    width: 100%;
    padding: 0 44px;
    border: 1px solid #c7c7c7;
    -moz-appearance: textfield;
    text-align: center;
    outline: none;

    &:disabled {
      opacity: 0.4;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.textContainer {
  .typeText {
    font-size: 14px;
  }
  .noticeText {
    margin-top: 7px;
    color: #6d8494;
    font-size: 12px;
  }
}

@import "../../../common/scssStyles/variables/colors";

.slideContainter {
  position: fixed;
  overflow-x: hidden;
  top: 0px;
  width: 100%;
  bottom: 0px;
  left: 0px;
  z-index: 1501;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-radius: 0px !important;
}

.popupSearch {
  width: 100vw;
  position: sticky;
  top: 0;
  padding-bottom: 10px;
  padding-top: 6px;
  background-color: $blue;
}

.closeBtnTitleContainer {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;

  .searchTitle {
    color: white;
    flex-grow: 2;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
  }

  .closeIconBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    z-index: 1;
    background-color: transparent;
    border: none;

    .closeIcon {
      color: white !important;
    }
  }
}

.searchInputIconContainer {
  height: 40px;
  position: relative;
  width: 95%;
  margin: 12px auto 0 auto;

  .searchInput {
    font-family: "Roboto";
    height: 100%;
    width: 100%;
    padding: 0px 35px 0px 15px;
    outline: none;
    border: none;
    border-radius: 10px;
  }
  .searchIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    color: $blue;
  }
}

.listHeaderTitle {
  color: $dark;
  font-weight: bold;
  padding: 10px 15px;
  line-height: 36px;
  font-size: 18px;
}

.airportListGroup {
  margin-top: 10px !important;
}

.airportList {
  padding: 10px 15px;
  font-weight: 400;
  color: $dark;
  line-height: 20px;
}

.noResultText {
  margin: 12px;
}

.container {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  height: 100%;
  width: 100%;

  .icon {
    margin-left: 20px;
  }

  &:hover {
    cursor: pointer;
  }
}

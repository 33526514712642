.btn {
  background-color: #2f80ed;
  color: white;
  border: none;
  width: 95%;
  display: block;
  margin: 20px auto 0 auto;
  padding: 20px;
  border-radius: 7px;
  font-size: 1.05em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

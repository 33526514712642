@import "../../../../common/scssStyles/variables/colors";

@mixin title {
  color: $blue;
  font-weight: 500;
}

@mixin listOfLinks {
  display: inline-block;
  list-style: none;
  margin-top: 20px;
  margin-left: 4px;
  li {
    color: #fff;
    font-weight: 300;
    font-size: 0.8em;
    line-height: 20px;
    &:hover {
      color: $blue;
      cursor: pointer;
    }
  }
}

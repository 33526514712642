.container {
  max-width: 950px;
  width: 90%;
  margin: 20px auto;
}

.editSearchContainer {
  width: 100%;
  margin: 20px 0;
}

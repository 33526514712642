.divFeaturedIn {
  //   background-color: #f7f7f7;
  padding-top: 50px;
  padding-bottom: 40px;
  .container {
    max-width: 1200px;
    width: 85%;
    margin: auto;

    @media screen and (max-width: 780px) {
      width: 100%;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  .col {
    padding: 0 5px;
    margin: 0 auto;
  }
}

.airlineFeatured {
  display: block;
  text-align: center;
  font-size: 30px;
  font-family: "Poppins";
  font-weight: 400;
  color: #000;
}

.featuredInContainer {
  margin-top: 25px;
}

.feature_img_irrawady_burma {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: 12px -10px;
  background-size: 1000px;
  width: 105px;
  height: 65px;
  float: left;
}
.feature_img_goaway {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -239px -12px;
  background-size: 1000px;
  width: 100px;
  height: 65px;
  float: left;
}
.feature_img_honeycamber {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -333px -12px;
  background-size: 1000px;
  width: 100px;
  height: 65px;
  float: left;
}
.feature_img_ncra {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -397px -93px;
  background-size: 1000px;
  width: 135px;
  height: 65px;
  float: left;
}
.feature_img_mizzima_burma {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: 10px -93px;
  background-size: 1000px;
  width: 105px;
  height: 65px;
  float: left;
}
.feature_img_malay_mail_online {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -568px -26px;
  background-size: 1000px;
  width: 115px;
  height: 65px;
  float: left;
}
.feature_img_sd_asia {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -563px -96px;
  background-size: 1000px;
  width: 108px;
  height: 65px;
  float: left;
}
.feature_img_today {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -853px -96px;
  background-size: 1000px;
  width: 81px;
  height: 65px;
  float: left;
}
.feature_img_mizzima_eng {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -816px -16px;
  background-size: 976px;
  width: 127px;
  height: 65px;
  float: left;
}
.feature_img_mingalapar {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -698px -25px;
  background-size: 1000px;
  width: 119px;
  height: 65px;
  float: left;
}

.feature_img_myan_biz_today {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -254px -86px;
  background-size: 960px;
  width: 103px;
  height: 65px;
  float: left;
}
.feature_img_coconut_bkk {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -402px -163px;
  background-size: 1000px;
  width: 90px;
  height: 65px;
  float: left;
}
.feature_img_coconut_ygn {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -135px -158px;
  background-size: 1000px;
  width: 90px;
  height: 65px;
  float: left;
}
.feature_img_yangon_life {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -259px -158px;
  background-size: 1000px;
  width: 137px;
  height: 65px;
  float: left;
}
.feature_img_irrawaddy {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -450px -17px;
  background-size: 1000px;
  width: 110px;
  height: 65px;
  float: left;
}
.feature_img_travel_leisure {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -10px -155px;
  background-size: 1000px;
  width: 100px;
  height: 65px;
  float: left;
}
.feature_img_myanmar_times {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -126px -89px;
  background-size: 1000px;
  width: 142px;
  height: 65px;
  float: left;
}
.feature_img_new_light {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -126px -12px;
  background-size: 1000px;
  width: 122px;
  height: 65px;
  float: left;
}
.feature_img_tech_in_asia {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/4cplIKEXfoJxCom2FcfeEn/ed5acd53b016e5d2fe62251dd7c60d37/features_new-min.png");
  background-position: -684px -98px;
  background-size: 1000px;
  width: 142px;
  height: 65px;
  float: left;
}

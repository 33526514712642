.container {
  display: flex;
  align-items: center;

  & > p {
    margin-right: 15px;
  }
}

.labelText {
  font-size: 14px;
  font-weight: 200;
}

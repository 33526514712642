.logoutBtn {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  border: none;
  color: white;
  padding: 5px 12px;
  font-family: "Poppins", sans-serif;
  transition: all ease-out 0.5s;
  font-weight: 300;
  &:hover {
    background-color: red;
    color: white;
    cursor: pointer;
    font-weight: 400;
  }
}

.container {
  @media (max-width: 941px) {
    margin-top: 20px;
  }
}

.labelText {
  font-size: 14px;
  font-weight: 300;
  @media (max-width: 941px) {
    font-size: 16px;
    font-weight: 400;
  }
}

.radioLabelText {
  font-size: 14px;
}

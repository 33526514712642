.loginFormContainer {
  width: 350px;
  height: 380px;
  background-color: white;
  position: relative;
  border-radius: 12px;
  margin: 70px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 780px) {
    width: 96%;
  }

  .planeImg {
    position: absolute;
    bottom: 96%;
    width: 250px;
  }

  .profileLogoImg {
    width: 50px;
    height: 50px;
  }

  h2 {
    font-weight: 400;
    font-size: 20px;
    margin-top: 12px;
    letter-spacing: 1.6px;
  }

  form {
    width: 70%;
    margin-top: 12px;
    @media (max-width: 780px) {
      width: 300px;
    }
  }
}

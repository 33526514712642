@import "../scssStyles/information/mixins";

.informationContainer {
  p {
    @include title;
  }

  @media (max-width: 780px) {
    text-align: center;
  }
}

.imagesContainer {
  margin-top: 20px;
  a {
    margin-right: 12px;
  }
}

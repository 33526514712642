.clickDetectContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.container {
  border: none;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }
}

.inputContainer {
  padding-left: 15px;
  width: 200px;
  text-align: start;
  .labelText {
    margin-bottom: 12px;
    font-size: 12px;
    color: #a5a7a6;
  }

  .valueText {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 16px;
  }
}

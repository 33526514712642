.button {
  height: 100%;
  width: 100px;
  border: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
  font-size: 18px;
  &:hover {
    cursor: pointer;
  }
}
.sfBtnColor {
  background-color: #f7911e;
}

.colBtnColor {
  background-color: #0f3072;
}

.nav {
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 80%;
  min-width: 750px;
  margin: auto;
  .img {
    height: 65px;
  }
  .flymyaLogo {
    width: 160px;
  }
}

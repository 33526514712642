.container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 75vh;
  position: relative;
}

.sfBGImg {
  background-image: url(../../../assets/images/sfBgImg.jpg);
}

.colBGImg {
  background-image: url(../../../assets/images/colBgImg.jpg);
}

.testUserBGImg {
  background-image: url(../../../assets/images/loginImgs/loginPageBGImg.jpg);
}

.counterContainer {
  padding: 18px 0;

  .typeText {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
  }
  .noticeText {
    text-align: center;
    font-size: 12px;
    color: #6d8494;
    margin-top: 7px;
  }
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  margin: 12px auto 0 auto;
  p {
    font-size: 30px;
  }
  button {
    border: 1px solid #00aeef;
    border-radius: 100%;
    padding: 5px;
    background: #fff;
    color: #00aeef !important;
  }
}

@import "../../../common/scssStyles/variables/colors";

.popperPaper {
  background-color: #fff;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 150px;
  button {
    background-color: #fff;
    margin-bottom: 10px;
    height: 40px;
    text-align: start;
    padding-left: 12px;
    font-size: 14px;
    border: none;
    border-radius: 7px;

    &:hover {
      cursor: pointer;
      color: $blue;
    }
  }
}

.active {
  background-color: #e8e8e8 !important;
  font-weight: bold;
  &:hover {
    color: $dark !important;
  }
}

@import "../../../common/scssStyles/variables/colors";

.divider {
  border-bottom: 1px solid #e4e4e4;
  position: relative;
}

.swapBtn {
  position: absolute;
  background-color: white;
  border: 1px solid #e6e7e8;
  padding: 6px;
  border-radius: 50px;
  z-index: 1;
  left: 25%;
  top: -20px;
}

.icon {
  color: $grey;
}

.container {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & > p {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
  }
}

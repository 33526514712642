.divAirlinePartner {
  padding-top: 20px;
  margin-bottom: 30px;

  .container {
    max-width: 1200px;
    width: 85%;
    margin: auto;

    @media screen and (max-width: 780px) {
      width: 100%;
    }
  }
  .bgWhite {
    display: flex;
    justify-content: space-around;
    padding: 0 75px;
    margin-bottom: 20px;
    margin-top: 5px;
    flex-wrap: wrap;
  }
}

.airlinePartner {
  display: block;
  text-align: center;
  font-size: 30px;
  font-family: "Poppins";
  font-weight: 400;
  color: #000;
}

.airline_gma {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: -200px -67px;
  background-size: 410px;
  width: 184px;
  height: 75px;
  float: left;
}
.airline_airkbz {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: -18px -144px;
  background-size: 430px;
  width: 184px;
  height: 75px;
  float: left;
}

.airline_mna {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: 182px 12px;
  background-size: 390px;
  width: 184px;
  height: 75px;
  float: left;
}
.airline_myp {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: -16px -280px;
  background-size: 400px;
  width: 184px;
  height: 75px;
  float: left;
}
.airline_air_thanlwin {
  background-image: url("https://images.ctfassets.net/7rpik7r2pwv0/2c38yH74IN2IQi1nznIWZd/c81ce0a40df17bf6ad68f9d63d5745df/airlines_new.png");
  background-position: -213px -134px;
  background-size: 400px;
  width: 184px;
  height: 75px;
  float: left;
}

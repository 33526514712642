@import "../../../common/scssStyles/variables/colors";
@import "../scssStyles/information/mixins";

.informationContainer {
  p {
    @include title;
  }
  ul {
    @include listOfLinks;
  }
}

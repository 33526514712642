.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    margin-top: 30px;
    width: 400px;

    @media (max-width: 470px) {
      width: 300px;
    }
  }

  & > h3 {
    margin-top: 40px;
    color: #9a9a9a;
    font-size: 100px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    @media (max-width: 470px) {
      font-size: 60px;
    }
  }
  & > p {
    font-family: "Poppins", sans-serif;
    @media (max-width: 470px) {
      text-align: center;
      font-size: 15px;
      padding: 0 50px;
    }
  }

  & > button {
    margin-top: 60px;
    border: 1px solid #00b0ff;
    color: #00b0ff;
    background-color: white;
    padding: 15px;
    border-radius: 12px;
    font-family: "Poppins", sans-serif;
    &:hover {
      background-color: #ebebeb;
      cursor: pointer;
    }
  }
}

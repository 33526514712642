@import "../../../common/scssStyles/variables/colors";
@import "../scssStyles/information/mixins";

.informationContainer {
  p {
    @include title;
  }

  @media (max-width: 780px) {
    text-align: center;
  }
}

.rowContainer {
  width: 80%;
  justify-content: space-between;
  display: flex;
  margin: 12px 0;
  @media (max-width: 780px) {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
}

.imgsContainer {
  margin-top: 20px;
}

.visaMaster {
  background-image: url("../../../assets/images/footerLogos/visa-master.png");
  width: 70px;
  height: 40px;
}
.mobileBanking {
  background-image: url("../../../assets/images/footerLogos/mobile-banking.png");
  width: 70px;
  height: 40px;
}
.mpu {
  background-image: url("../../../assets/images/footerLogos/mpu.png");
  width: 70px;
  height: 40px;
}
.payment123 {
  background-image: url("../../../assets/images/footerLogos/payment-123.png");
  width: 70px;
  height: 40px;
}
.kbzQuickPay {
  background-image: url("../../../assets/images/footerLogos/kbz-quickpay.png");
  width: 70px;
  height: 40px;
}

.cod {
  background-image: url("../../../assets/images/footerLogos/cod.png");
  width: 70px;
  height: 40px;
}

.wavePay {
  background-image: url("../../../assets/images/footerLogos/wave-pay.png");
  width: 70px;
  height: 40px;
}
.kbzPay {
  background-image: url("../../../assets/images/footerLogos/kbz-pay.png");
  width: 70px;
  height: 40px;
}
.onePay {
  background-image: url("../../../assets/images/footerLogos/one-pay.png");
  width: 70px;
  height: 40px;
}
.mPitesan {
  background-image: url("../../../assets/images/footerLogos/m-pitesan.png");
  width: 70px;
  height: 40px;
}

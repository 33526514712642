.container {
  height: 100vh;
  width: 100vw;
  background-image: url(../../../assets/images/loginImgs/loginPageBGImg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 60px;

  @media (max-width: 500px) {
    height: 100vh;
  }
}

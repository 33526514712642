.container {
  background-color: transparent;
  color: inherit;
  height: 100%;
  font-size: inherit;
  display: flex;
  align-items: center;
  border: none;
  .icon {
    margin-left: 23px;
  }
  &:hover {
    cursor: pointer;
  }
}

.container {
  background-color: #ebecf0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  padding: 13px;
  margin: 20px auto 0 auto;
  width: 95%;

  p {
    font-weight: bold;
  }
}

.labelText {
  font-size: 0.95em;
  font-weight: 200;
}

.informationContainer {
  background-color: #2e2e2e;
  padding-top: 50px;
}

.gridContainer {
  max-width: 1200px;
  width: 85%;
  height: 90%;
  margin: auto;
  row-gap: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media screen and (max-width: 1270px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 945px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 780px) {
    width: 100%;
    grid-template-columns: 1fr;
    row-gap: 80px;
  }
}

.container {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.iconFilterTextContainer {
  display: flex;
  .filterIcon {
    font-size: 16px !important;
  }
  p {
    margin-left: 7px;
    font-size: 14px;
  }
}

.horizontalLine {
  margin: 0px 12px;
}

.dropDownBtn {
  width: 238px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e4e4e4;
  padding: 0 12px;
  background: transparent;
}

.filterPopoverContainer {
  position: relative;
}

.emptyText {
  color: rgb(184, 184, 184);
}

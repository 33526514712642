.airlnelbl {
  font-size: 14px;
  color: #7f91a8;
  font-weight: normal;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  line-height: 20px;
}

.circle {
  border-radius: 20px;
  border: 1px solid #1ba0e2;
  background: #fff;
  width: 8px;
  height: 8px;
  display: block;
  margin-left: -5px;
  float: left;
}
.fullCircle {
  border-radius: 20px;
  border: 1px solid #1ba0e2;
  background: #fff;
  width: 8px;
  height: 8px;
  display: block;
  margin-left: -5px;
  background-color: #00aeef;
  float: left;
}
.circle-line {
  width: 1px;
  height: 73px;
  background-color: #e6e7e8;
  display: block;
  margin: 15px 0px 5px 0px;
}
.arlneLogo {
  float: left;
}
.arlneLogo img {
  width: 30px;
  margin-left: -3px;
}
div.airlineInfo {
  display: flex;
  align-items: center;
}
div.airlineInfo.departureFlight {
  float: left;
  span img {
    width: 50px;
    margin-right: 10px;
  }
}
.airlnelbl.departureAirline {
  margin-left: 0px !important;
}
.airlineName {
  font-size: 14px;
  font-family: "Roboto", Myanmar3;
  font-weight: 700;
  color: #7f91a8;
}
.airlineClass {
  color: #7f91a8;
  font-size: 12px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
}
.departFlightLbl {
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  font-size: 14px;
}
.m-bottom-50 {
  margin-bottom: 50px;
}
.baggageAllowance {
  font-size: 13px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  line-height: 22px;
  span {
    color: #7f91a8 !important;
  }
}
.panel2div {
  .MuiAccordionDetails-root {
    margin-left: -218px;
  }
}
.borderInfo {
  border-bottom: 3px solid #1ba0e2;
  line-height: 30px;
  width: 95px;
}

.divInfo {
  padding-top: 20px;
  border-top: 1px solid #ddd;
}
.fadein,
.fadeout {
  max-height: 0;
  overflow-y: hidden;
  -webkit-transition: max-height 300ms ease-out;
  -moz-transition: max-height 300ms ease-out;
  -o-transition: max-height 300ms ease-out;
  transition: max-height 300ms ease-out;
}
.fadein {
  max-height: 200px;
}
.selectedClass {
  width: 158px;
  height: 158px;
  border: 1px solid #e6e7e8;
  border-radius: 5px;
  background-image: linear-gradient(#0e99eb, #d9f0f3 200%);
  position: relative;
  margin-right: 5px;
}
.unSelectedClass {
  width: 158px;
  height: 158px;
  border: 1px solid #e6e7e8;
  border-radius: 5px;
  position: relative;
  margin-right: 5px;
  .fareClass {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 500;
    font-size: 16px;
    color: #231f20 !important;
    text-transform: uppercase;
  }
}
.divFareClass {
  padding: 13px !important;
  cursor: default;
}
.selectClassLbl {
  font-size: 16px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 500;
  color: #ffffff !important ;
  float: left;
  min-width: 100%;
}
.basicFare {
  font-size: 15px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 700;
  color: #ffffff !important ;
  float: left;
  span {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
  }
}
.refundPolicy {
  font-size: 14px;
  font-family: "Roboto", Myanmar3 !important;
  color: #ffffff !important ;
  bottom: 16px;
  left: 12px;
  position: absolute;
}
.unselectRefund {
  color: #777777 !important;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  float: left;
}
.farePrice {
  font-size: 15px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 700;
  color: #f7911e;
}
.divFarePrice {
  position: absolute;
  bottom: 60px;
  left: 12px;
  span {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
  }
}
.choosePack {
  position: absolute;
  bottom: 16px;
  text-align: center;
  left: 12px;
  button {
    color: #00aeef !important;
    font-size: 12px !important;
    border: 2px solid #00aeef !important;
    border-radius: 20px !important;
    text-transform: capitalize !important;
    padding: 5px 10px !important;
    width: 130px;
    span {
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 700;
    }
  }
  button:hover {
    background-color: #00aeef !important;
    color: #fff !important;
  }
  a {
    text-decoration: none;
    color: #00aeef !important;
  }
  a:hover {
    text-decoration: none;
    color: #fff !important;
  }
}
.fromAirportSelected {
  font-size: 14px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 700;
  color: #231f20 !important;
}
.editForm {
  width: 100%;
  input {
    font-size: 14px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20;
  }
}
.choosePassenger {
  // max-width: 345px;
  .MuiInputBase-input {
    font-size: 14px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    color: #231f20;
    width: 280px;
    padding: 10px !important;
  }
  .MuiInput-root {
    border: 1px solid #ddd;
    padding-right: 10px;
    border-radius: 5px;
  }
  input {
    border: none !important;
  }
  .Mui-focused {
    border: 1px solid #00aeef !important;
    border-radius: 5px;
    .MuiInputBase-input {
      border: none !important;
    }
  }
  .MuiCard-root {
    width: 300px;
    padding: 20px;
    position: absolute;
    z-index: 1;
  }
}
.DateRangePickerInput__withBorder {
  border: none !important;
  width: 100%;
}
.DateInput_input__disabled {
  background-color: #fff !important;
  display: none;
  visibility: hidden;
}
.editSearch {
  top: 0px !important;
  .DateInput_input__disabled {
    background-color: #fff !important;
    border: none !important;
  }
  .mdatepicker .SingleDatePickerInput__withBorder,
  .mdatepickerReturn .SingleDatePickerInput__withBorder {
    min-width: 100% !important;
    max-height: 100% !important;
  }
  .SingleDatePicker .DateInput_input {
    margin-left: 0px !important;
  }
  .DateRangePicker {
    width: 100% !important;
    padding-left: 0px !important;
  }
  .DateRangePickerInput_calendarIcon,
  .DateRangePickerInput_arrow_svg {
    display: none !important;
  }
  .DateRangePickerInput_arrow {
    width: 20px;
  }
  .return {
    margin-left: 152px;
  }
}
.ageSelect {
  width: 53%;
  float: left;
  margin: 5px 0px;
  .ageType {
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 14px;
  }
  .ageLimit {
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 12px;
    color: #6d8494;
  }
}
.chooseAge {
  width: 40%;
  float: right;
  margin: 10px 0px;
  .react-numeric-input {
    width: 100%;
    padding-left: 2px;
    height: 30px;

    b {
      background-color: #fff !important;
      border: none !important;
      border-right: 1px solid #c7c7c7 !important;
      border-left: 1px solid #c7c7c7 !important;
      width: 4.3ex !important;
      cursor: pointer !important;
    }
  }
  .numeric-counter {
    padding: 0px !important;
    height: 30px;
    width: 117px;
    border: 1px solid #c7c7c7 !important;
  }
}

.departAirline {
  float: left;
  margin-left: 10px;
  margin-top: -5px;
}
.navigation {
  width: 12%;
  float: left;
  text-align: center;
  max-height: 12px;
  .fromToIcon {
    width: 35px;
    color: #000 !important;
    margin-top: -5px;
  }
}

.m-bottom-30 {
  margin-bottom: 30px;
}
.editSearch.airport-popup {
  margin-left: 34% !important;
  margin-top: 10px !important;
}
.editSearch.airport-popup:after {
  top: -3px !important;
  left: 10%;
}
.divDepart {
  margin-bottom: 50px !important;
}
.divArrival {
  margin-bottom: 30px !important;
}
.divFare {
  text-align: center !important;

  span {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
  }
}
.citizen,
.choosePassenger {
  padding: 5px 10px 20px 20px !important;
}

@media (min-width: 360px) and (max-width: 767px) {
  .fare-packages-container {
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 780px) {
  .fare-packages-container {
    width: 100% !important;
  }
}
@media (min-width: 781px) {
  .fare-packages-container {
    width: 2500px !important;
  }
}

// @media (max-width: 800px) {
@media (max-width: 959px) {
  .editSearch .mdatepicker .SingleDatePickerInput__withBorder,
  .editSearch .mdatepickerReturn .SingleDatePickerInput__withBorder {
    min-width: 100% !important;
    max-height: 100% !important;
    background: transparent;
    margin-top: -45px;
  }
  .fromToIcon {
    margin-top: -8px !important;
    font-size: 1.875rem;
  }
  .header {
    // height: 40px !important;
    background-color: #fff !important;
    .homeContainer {
      // height: 40px !important;
      padding: 0px !important;
    }
  }
  #root > .header > .homeContainer,
  #root > .bgWhite > .header > .homeContainer,
  #root > .MuiGrid-container > .header > .homeContainer {
    & > .MuiGrid-item:first-child {
      display: none;
      visibility: hidden;
    }
  }
  .headerLogo {
    display: none !important;
  }
  .divSearch-container {
    margin-top: 40px !important;
  }
  .summaryFlight,
  .serachDetail {
    width: auto !important;
    padding: 10px;
    background-color: #f4f3f4;

    .fromAirport {
      font-size: 14px !important;
      float: left;
      width: 43%;
      text-align: left;
    }

    .dateInfo {
      text-align: center;
      width: 100%;
      padding-right: 0px !important;
    }
  }
  .btnEditSearch {
    float: none !important;
    span {
      pointer-events: none;
    }
  }

  .detailBox {
    padding: 10px !important;
    width: 100% !important;
    background-color: #fff;
  }
  .citizen {
    padding: 5px 20px !important;
  }
  .MuiInputAdornment-root {
    float: right;
    margin-top: 3px;
    .MuiSvgIcon-root {
      color: #2699fb !important;
      float: right;
    }
  }

  .choosePassenger {
    padding: 5px 20px !important;
    max-width: auto !important;
    .MuiInputBase-input,
    .MuiFormControl-root {
      width: auto !important;
      font-size: 16px !important;
    }
    .MuiInputAdornment-root {
      float: right;
      margin-top: 8px;
      .MuiSvgIcon-root {
        color: #ccc !important;
        float: right;
      }
    }
  }

  .fadein {
    max-height: 1200px !important;
  }
  .divDepart,
  .divArrival {
    margin-bottom: 7px !important;
    .airlnelbl {
      font-size: 15px !important;
      position: relative !important;
      margin-top: 0px !important;
    }
  }
  .progressbar {
    padding-inline-start: 0px !important;
  }
  .progressbar li {
    span {
      margin-top: -6px !important;
      margin-left: 4px !important;
    }
  }
  .DateInput_input__disabled {
    font-style: normal !important;
  }
  .progressbar li:before {
    font-size: 12px !important;
    width: 14px;
    height: 14px;
    margin: 0px !important;
  }

  // .progressbar .completed:before {
  //   margin: 0px 5px !important;
  // }
  .divArrival {
    margin-bottom: 10px !important;
  }
  .airlinLogo {
    text-align: right;
    float: right;
    width: 45%;
    img {
      width: 90%;
    }
  }
  .departFlightLbl {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 700;
    font-size: 15px !important;
  }
  .flightDetailInfo {
    width: 55%;
    float: left;
    height: auto;
    margin-bottom: 10px;
  }
  .divflightDetails {
    .divInfo {
      padding: 5px;
    }
  }
  .divPackages {
    height: auto;
    .MuiGrid-item {
      width: 48%;
      margin-right: auto;
    }
    .selectedClass,
    .unSelectedClass {
      width: 96%;
      margin: auto;
      margin-bottom: 10px !important;
    }
  }
  .circle-line {
    height: 62px !important ;
  }
  .fare {
    color: #00a651 !important;
    font-size: 18px !important;
  }
  .btnChoose {
    min-height: 40px;
    margin-top: 30px !important;
    span {
      pointer-events: none;
    }
  }
  .divFare {
    text-align: right !important;
  }
  .serachDetail {
    .details {
      width: 25%;
      float: left;
      margin-top: -30px;
    }
  }
  .infoSection {
    .departTime {
      position: absolute;
      margin-top: 20px;
      margin-left: 43px;
    }
  }
  .choosePack {
    margin: auto;
  }
  .choosePack button {
    width: auto !important;
  }
  .flightInfo {
    margin-left: 0px !important;
  }
  .flightInfo {
    overflow: hidden;
  }

  .infoLbl {
    font-size: 16px !important;
    overflow: hidden;
  }
  .mm-flightInfo {
    .infoLbl {
      text-indent: 0px !important;
      width: 120px !important;
    }
  }
  .chooseReturnDate {
    height: 60px !important;
    .MuiSwitch-root {
      overflow: visible !important;
      margin: 0px !important;
    }
  }
  .searchFormLabel {
    line-height: 35px;
    color: #231f20 !important;
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    font-size: 16px !important;
    margin-top: 5px !important;
    span {
      font-size: 16px !important;
    }
  }
  .searchFormLabel.dateLable {
    line-height: 50px !important;
  }
  .divSearch {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
    margin-top: 20px !important;
    .swapDestination {
      border: 1px solid #e6e7e8;
      background: #fff;
      border-radius: 50px;
      padding: 10px;
      margin: 0px;
      right: 0px;
      top: -5px;
      z-index: 1;
      position: absolute;
    }
    .clickAwayDiv {
      display: inline;
      float: none;
    }
    .mobile-from-to {
      margin-top: 0px !important;
      .MuiFormControl-root {
        width: 100%;
      }
      .MuiInputBase-input {
        width: auto !important;
        font-size: 16px !important;
      }
    }
    .DateInput_input {
      font-size: 16px !important;
      width: 100%;
      box-sizing: border-box;
      height: 41px;
    }
  }
  .m-top-10 {
    margin-top: 10px !important;
  }
  .editSearch {
    .DateInput {
      width: 100% !important;
    }
    .departfrom {
      height: auto !important;
      margin-top: 0px !important;
      border: none !important;
      padding: 0px !important;
      width: 100% !important;
      max-width: 100% !important;
      .mdatepicker,
      .mdatepickerReturn {
        border-bottom: none !important;
        height: auto !important;
        margin-top: -2px !important;
      }
      .mdatepicker {
        margin-top: -26px;
        background: transparent;
      }
      label,
      .mdatepicker .SingleDatePicker,
      .mdatepickerReturn .SingleDatePicker {
        width: 100% !important;
      }
      .chooseReturnDate {
        display: none !important;
      }
      .DateInput_input {
        width: 100% !important;
        box-sizing: border-box;
        height: 41px;
        text-align: left !important;
        padding: 12px 10px !important;
        outline: none !important;
        -webkit-appearance: none;
        -moz-appearance: none;
      }
      .fieldLbl {
        line-height: 18px;
        color: #231f20 !important;
        font-family: "Roboto", Myanmar3;
        font-weight: 400;
        font-size: 16px !important;
        margin-top: 5px !important;
      }
    }
  }
  .fieldLbl {
    line-height: 60px;
    font-size: 16px !important;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    color: #a5a7a6 !important;
  }
  .departfrom.return,
  .mdatepickerReturn {
    border-bottom: none !important;
  }

  .mobile_passenger_type {
    position: absolute;
    top: 30%;
    padding: 20px;
    margin: 0 auto;
    width: 80%;
    left: 50%;
    transform: translate(-50%);
    touch-action: none;
    outline: none !important;
  }
  .mobile_passenger_type.mobileTripType {
    padding: 0px;
  }
  .mobile_passenger_type .chooseAge .numeric-counter {
    width: 110px !important;
  }
  .chooseAge {
    width: auto !important;
  }
  .btnSearchFlight {
    text-align: center;
    min-width: auto !important;
    button {
      margin: 20px 0px !important;
      float: none !important;
    }
  }

  .editFromAiport {
    position: relative;
  }
  div.airlineInfo {
    margin-top: -27px;
  }
  div.airlineInfo .airlnelbl {
    line-height: 20px;
    margin-left: 7px;
    margin-top: -13px;
  }
  .arlneLogo {
    margin-top: 5px;
  }
  .arlneLogo img {
    width: 40px;
  }
}
.center {
  text-align: center !important;
}
.full-width {
  width: 100%;
}
.noResult {
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  color: #2699fb;
  padding: 10px 20px;
  font-size: 18px;
}
.baggage_icon {
  float: left;
  margin-right: 5px;
}
.baggage_kilo {
  position: absolute;
  margin-left: -24px;
  font-family: "Roboto", Myanmar3;
  font-weight: 500;
  color: #00aeef;
  font-size: 12px;
  line-height: 25px;
}
// .searchBox {
//   display: inline;
// }
.searchErrMessage {
  input {
    border: 1px solid #ff0000 !important;
  }
  .MuiInputBase-input {
    border: 1px solid #ff0000 !important;
  }
  .errMessage {
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    color: #ff0000;
    font-size: 12px;
  }
}

.noFlightMessage {
  margin: 0 auto;
  text-align: center;
  .title {
    font-family: "Poppins", Myanmar3;
    font-weight: 300;
    font-size: 32px;
    color: #231f20;
  }
  .tip {
    font-family: "Roboto", Myanmar3;
    font-weight: 400;
    color: #8d8d8d;
    font-size: 18px;
  }
  button {
    background-color: #00aeef;
    padding: 5px 20px;
    span {
      font-size: 16px;
      color: #fff;
      font-family: "Roboto", Myanmar3;
      font-weight: 500;
      text-transform: none;
    }
  }
  button:hover {
    background-color: #00aeef;
  }
}
.react-numeric-input b:nth-child(2) i {
  background-color: #23af2e !important;
}
.react-numeric-input b:last-child i {
  background-color: #ff5757 !important;
}
@media (min-width: 800px) {
  .divSearch {
    .swapDestination {
      margin-top: 35px !important;
      position: absolute;
      margin-left: -22px !important;
      cursor: pointer;
    }
  }
  .transit {
    display: none;
  }
}

.alignTextLeft {
  text-align: left !important;
}
.MuiFormControlLabel-root {
  margin-right: 0 !important;
}
.policyPopper {
  width: 400px;
  background-color: #373e4c !important;
  padding: 10px;
  margin-left: 26% !important;
  top: -20px !important;
  border-radius: 3px;
  z-index: 100;
  font-size: 12px;
  line-height: 20px;
  font-family: "Roboto", Myanmar3;
  font-weight: 400;
  color: #fff !important;
  // ul {
  //   padding-inline-start: 25px !important;
  // }
}

.top.policyPopper::after {
  content: "";
  transform: rotate(-45deg);
  background: #373e4c !important;
  position: absolute;
  z-index: -1;
  right: 44%;
  margin-left: -10px;
  width: 26px;
  height: 14px;
  overflow: hidden;
  margin-top: 20px;
  bottom: -2px;
}
.bottom.policyPopper::after {
  content: "";
  width: 26px;
  height: 14px;
  transform: rotate(-45deg);
  background: #373e4c;
  position: absolute;
  z-index: -1;
  top: -3px;
  right: 46%;
  position: absolute;
}
.policyInfo {
  float: left;
  width: 20px;
  height: 20px;
  filter: invert(0.5);
}
.policyInfoSelected {
  padding-left: 3px;
  float: left;
  width: 13px;
  height: 18px;
}
.policyBox {
  cursor: default;
  width: auto;
  float: left;
  z-index: 1000;
}

@media (max-width: 600px) {
  .divSearch .MuiInputBase-input {
    width: 100% !important;
  }
  .mm-flightInfo {
    .borderInfo {
      width: auto !important;
    }
  }
  // .language-select {
  //   max-width: 97% !important;
  // }
  // .mm-progress li:nth-child(2) {
  //   width: 36%;
  // }
  .eng-progress.progressbar {
    .progressbar.mm-progress li {
      min-width: 46px !important;
      width: 15% !important;
      height: 20px;
    }
  }
  .mm-progress.progressbar,
  .eng-progress.progressbar {
    // width: 100% !important;
    li {
      min-width: 40px !important;
      width: 15% !important;
    }
  }
  // .mm-progress.progressbar li {
  //   min-width: 80px !important;
  //   width: 15% !important;
  // }
  // .mm-progress.progressbar li:nth-child(2) {
  //   min-width: 100px !important;
  // }
  .flightPlaceholder,
  .arrivalInfo,
  .duration {
    display: none;
  }
  .policyPopper {
    width: calc(100% - 30px) !important;
    margin-left: 0px !important;
  }
  .policy0,
  .policy2 {
    .policyPopper {
      margin: 0px 5px !important;
      margin-left: 10px !important;
    }
    .top.policyPopper::after {
      right: calc(22% - 10px) !important;
    }
    .bottom.policyPopper::after {
      right: 22% !important;
    }
  }
  .policy-1,
  .policy1 {
    margin: auto !important;
    .top.policyPopper::after {
      left: calc(33% - 10px) !important;
    }
    .bottom.policyPopper::after {
      left: calc(28% - 10px) !important;
    }
  }
  .mm-flightInfo {
    .infoLbl {
      text-indent: 0px !important;
      width: 120px !important;
      font-size: 12px !important;
    }
    .flightInfo {
      text-indent: 0px !important;
    }
    span {
      font-size: 14px !important;
    }
  }
  .btnEditSearch {
    margin-top: 3px !important;
    padding: 2px 8px !important;
  }
  .progressbar {
    max-width: 100% !important;
  }
  .sortingBox {
    padding: 3px 10px;
    height: 25px !important;
    float: left !important;
    span {
      padding-right: 5px !important;
      float: left !important;
    }
    .MuiInput-root {
      float: left !important;
    }
  }
  .step-info {
    font-size: 12px !important;
  }
}
@media (min-width: 760px) and (max-width: 1000px) {
  .policyPopper {
    margin-left: 0% !important;
  }
  .policy-1 {
    .top.policyPopper::after {
      left: 30% !important;
    }
    .bottom.policyPopper::after {
      left: 30% !important;
    }
  }
  .divPackages .MuiGrid-item {
    width: 25% !important;
    margin: 0px !important;
  }
  .serachDetail {
    .m-top-10 {
      margin-top: 0px !important;
    }
  }
  .btnEditSearch {
    padding: 7px !important;
  }
  .borderInfo {
    width: 105px !important;
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .policyPopper {
    margin-left: 20% !important;
  }
  .policy-1 {
    .top.policyPopper::after {
      left: 46% !important;
    }
    .bottom.policyPopper::after {
      left: 30% !important;
    }
  }
}
@media (max-width: 400px) {
  .policy-1 {
    .top.policyPopper::after {
      left: 33% !important;
    }
    .bottom.policyPopper::after {
      left: 28% !important;
    }
  }
  .policy0,
  .policy2 {
    .top.policyPopper::after {
      right: calc(22% - 10px) !important;
    }
    .bottom.policyPopper::after {
      right: 22% !important;
    }
  }
}
.flag-select {
  float: right !important;
  // margin-top: 16px;
}
.flag-select .selected--flag--option {
  font-size: 14px !important;
  color: #000 !important;
}
.flag-select .arrow-down {
  color: #000 !important;
}
.flag-select button:focus {
  outline: none !important;
}
.sortingBox {
  text-align: right;
  margin: auto !important;
  height: 45px;
  .MuiInput-root {
    text-align: left !important;
    margin-right: 10px !important;
    width: 145px;
  }
  span {
    line-height: 34px;
    padding-right: 10px;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    font-size: 14px;
  }
}
#select-sortBy {
  .MuiMenu-paper {
    min-width: 200px !important;
    max-width: 310px !important;
    top: 350px;
    position: absolute;
    float: right;
  }
  .MuiMenuItem-root {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    font-size: 14px !important;
    min-height: 30px;
  }
  .MuiSelect-icon {
    right: -12px;
  }
}
#menu-sortBy {
  .MuiMenuItem-root {
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    font-size: 14px;
    min-height: 40px !important;
  }
}
#mui-component-select-sortBy {
  padding: 0px 0px 0px 10px !important;
  min-width: 100% !important;
  background-color: #fff !important;
  min-height: 30px;
  border-radius: 4px;
  line-height: 30px;
  font-family: "Roboto", Myanmar3 !important;
  font-weight: 400;
  font-size: 14px !important;
  border: 1px solid #e4e4e4;
}
.fareBreakdwonDetail {
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 14px;
  padding-top: 30px;
  line-height: 25px;
}
.fareBreakdownPrice {
  font-family: "Roboto" !important;
  font-weight: 500;
}
.mm-flightInfo {
  .borderInfo {
    width: 140px !important;
  }
}
#editSearchCloseBtn {
  position: absolute;
  right: 25px;
  padding-top: 10px;
}
@media (max-width: 350px) {
  .progressbar li:before {
    width: 12px !important;
    height: 12px !important;
  }
  // .progressbar.mm-progress li:nth-child(2) {
  //   min-width: 95px !important;
  // }
  // .progressbar.mm-progress li:nth-child(3) {
  //   min-width: 80px !important;
  // }
  // .progressbar.mm-progress li:first-child {
  //   min-width: 70px !important;
  // }
  // .progressbar.mm-progress li {
  //   min-width: 75px !important;
  // }
  // .progressbar.eng-progress li {
  //   min-width: 70px !important;
  // }
  .sortingBox {
    padding: 3px 0px !important;
  }
  .divSearch {
    .mobile-from-to .MuiFormControl-root,
    .MuiInputBase-input {
      width: 100% !important;
    }
  }
}
#root > .header > .homeContainer,
#root > .bgWhite > .header > .homeContainer,
#root > .MuiGrid-container > .header > .homeContainer {
  justify-content: center;
}
@media (max-width: 780px) {
  .notInApp.resultCard {
    .summaryFlight,
    .serachDetail {
      background-color: #f7f9fe;
    }
    .serachDetail {
      .detailBox {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        &:hover {
          border: 1px solid rgb(47, 128, 237) !important;
        }
      }
    }
    #fl-result-btn-editSearch,
    #mui-component-select-sortBy {
      box-shadow: rgba(50, 50, 93, 0.15) 2px 2px 5px -1px,
        rgba(0, 0, 0, 0.15) 0px 1px 3px -1px;
    }
    .infoLbl {
      color: rgb(47, 128, 237);
    }
    .btnChoose {
      background-color: rgb(47, 128, 237) !important;
    }
  }
  .flightDetailBox {
    .custDetailDiv:nth-child(3) {
      display: flex !important;
      flex-direction: column !important;
      .custFlightTransit {
        margin-top: -15px !important;
      }
    }
    .custDetailDiv:last-child {
      .custFlightTransit {
        margin-top: -5px !important;
      }
    }
  }
  .custDetailDiv .bgAirport {
    margin-left: 0 !important;
    margin-top: -5px !important;
  }
  .homeContainer .flightSearch .DateInput_input {
    width: 100% !important;
  }
}
@media (max-width: 375px) {
  .header {
    height: 50px !important;
  }
  #root > .header >,
  #root > .bgWhite > .header > {
    & > .MuiGrid-item:first-child {
      display: none;
    }
  }
  .progressbar li:before {
    width: 14px !important;
    height: 14px !important;
  }
  .arlneLogo img {
    width: 30px;
  }
  div.airlineInfo {
    margin-top: -18px !important;
  }
  .selectedDepartureBox div.arrivalInfo,
  .selectedDepartureBox div.duration {
    display: block !important;
  }
  .infoSection .airlnelbl,
  .infoSection .arlneLogo {
    margin-top: 0 !important;
  }
  .infoSection .departTime {
    margin-left: 0 !important;
    margin-top: 40px !important;
  }
  .infoSection .airlnelbl {
    line-height: 20px;
    font-size: 15px !important;
    max-width: 150px;
    margin-left: 10px;
  }
  .fare {
    font-size: 17px !important;
  }

  .mm-flightInfo .borderInfo {
    width: 70px !important;
    min-width: 70px;
  }
  .infoLbl {
    min-width: unset;
  }
}
@media (max-width: 370px) {
  .infoSection .airlnelbl {
    margin-left: -10px;
  }
  div.airlineInfo {
    margin-top: 0px !important;
  }
}
@media (min-width: 959px) and (max-width: 1024px) {
  .divSearch .DateInput {
    margin-right: 5px;
    margin-top: 5px;
  }
  .SingleDatePickerInput__withBorder {
    border: none !important;
  }
}
@media (max-width: 1024px) {
  .header {
    height: 50px !important;
  }
  #root > .header > .homeContainer,
  #root > .bgWhite > .header > .homeContainer,
  #root > .MuiGrid-container > .header > .homeContainer {
    .MuiGrid-item {
      display: flex;

      align-items: center;
      height: 50px;
    }
    .country-flag .country-label {
      display: none;
    }
    .language-selection {
      margin-top: 0 !important;
    }

    .flag-select .flag-option.has-label {
      padding: 0px 8px 3px 8px;
    }
  }
  .headerLogo {
    margin-top: 0 !important;
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  .resultCard .btnSearchFlight {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    button {
      padding: 8px 24px !important;
      margin-bottom: 20px !important;
      /* margin-right: 25px !important; */
      width: 30%;
    }
  }
  .SingleDatePickerInput__withBorder {
    background: transparent;
    border: none;
    /* border: 1px solid #dbdbdb; */
  }
  .divSearch .swapDestination {
    margin-top: 0 !important;
  }
}
.centerSummaryFlight + br {
  display: none;
}
@media (max-width: 599px) {
  .centerSummaryFlight {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .textRight {
      text-align: unset !important;
    }
    .fromAirport,
    .navigation {
      width: unset;
      float: none;
      text-align: center !important;
    }
  }
  .resultCard .btnSearchFlight {
    padding-left: 20px;
  }
  .resultCard .btnSearchFlight button {
    width: 100%;
  }
}

.fare {
  color: #00a651 !important;
  font-weight: bold;
}

/************************ Start product v3 new css *******************/

/****** start filter by airline *******/
.b2b-optional-label {
  display: inline-block;
}
.b2b-exchange {
  display: block;
  margin-top: 10px;
  font-size: 15px;
  .b2b-sp {
    font-size: 14px;
    font-weight: 600;
    float: none;
    padding-left: 10px;
  }
}

.choosePassenger {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.choosePassenger .MuiFormControl-root {
  width: 100% !important;
}

@media (max-width: 780px) {
  .choosePassenger {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.b2b-classtype {
  padding: 4px 10px 4px 15px;
  select {
    min-height: 40px;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    outline: none;
  }
}
.filter-airline {
  margin-top: 15px;
  .MuiSelect-icon {
    right: 10px;
  }
  .select-region {
    display: initial;
    position: relative;
  }
  .airline-select {
    .MuiInput-root {
      text-align: left !important;
      padding: 0px 0px 0px 10px !important;
      background-color: #fff !important;
      min-height: 30px;
      border-radius: 4px;
      line-height: 30px;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
      font-size: 14px !important;
      border: 1px solid #e4e4e4;
      margin: 1px 10px 0 10px;
      min-width: 235px;
    }
    span {
      line-height: 34px;
      padding-right: 10px;
      font-family: "Roboto", Myanmar3 !important;
      font-weight: 400;
      font-size: 14px;
    }
  }
  .MuiCard-root {
    position: absolute;
    z-index: 999;
    width: 235px;
    right: 10px;
    li {
      font-size: 14px;
    }
  }

  .checked-airline {
    width: 100%;
    display: inline-block;
    .airlineChecked {
      padding: 2px 0px 2px 10px;
    }
    .MuiSvgIcon-root {
      color: #00aeef !important;
    }
  }

  .export-pdf {
    float: right;
    padding: 0;
    color: #00aeef;
    cursor: pointer;
  }
  .export-pdf:hover {
    text-decoration: underline;
  }
}
/****** end filter by airline *******/

/***** start b2b show fares *****/
.b2b-borderInfo {
  width: 120px !important;
}
.b2b-flight-total,
.b2b-sp {
  float: right;
  color: #f7911e;
  text-align: left;
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  .fare-id {
    font-size: 13px;
    color: #000;
    font-weight: normal;
  }
}
.b2b-price-region button {
  float: right;
}
// .b2b-sp {
//   margin-right: 20px;
// }
.b2b-selling-total {
  font-weight: 700;
  font-size: 13px;
  color: #231f20;
}
.b2b-fares-div {
  width: 1500px !important;
  .b2b-active {
    color: #42aeef;
    font-size: 12px;
    width: 100%;
    display: inline-block;
  }
  .b2b-inactive {
    color: #27a1df;
    font-size: 12px;
    width: 100%;
    display: inline-block;
  }
  .b2b-refund {
    width: 100%;
    display: inline-block;
  }
  .b2b-fare-div {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .b2b-fa-right {
    position: absolute;
    right: 2px;
    top: 0px;
    font-size: 35px;
    color: #f7911e;
    cursor: pointer;
  }
  .divider-fare {
    width: 100%;
    margin: 2px 0px;
    float: left;
  }
  .divider-active {
    border-top: 1px solid #42aeef;
  }
  .divider-inactive {
    border-top: 1px solid #e6e7e8;
  }
  .passenger-fares {
    float: left;
    line-height: 18px;
    font-size: 13px;
  }
  .b2b-divFareClass {
    padding: 7px;
  }
  .show-fares {
    font-size: 12px;
  }
  .passenger-fares-active {
    color: #fff;
  }
  .passenger-fares-inactive {
    color: #231f20 !important;
  }
  .b2b-basicFare-inactive {
    color: #231f20 !important;
  }
  .b2b-selectedClass {
    background-color: #0065ef;
    background-image: none;
  }
  .b2b-unSelectedClass,
  .b2b-selectedClass {
    float: left;
    height: 180px !important;
    margin-top: -8px;
  }
  .b2b-farebreakdowns {
    float: left;
    width: 100px;
    margin-top: -5px;
  }
  .b2b-selling-fare {
    width: 100%;
    font-size: 14px;
    span {
      background-color: #dcf8fd;
      color: #47afd4;
      padding: 3px 5px;
      border-radius: 5px;
      font-weight: bold;
      font-size: 13px !important;
    }
    .b2b-sp {
      background: none !important;
      padding: 0 !important;
    }
  }
}
/***** end b2b show fares *****/

/****** start export pdf ********/
#pdfdiv {
  padding: 20px;
  width: 890px;
  display: inline-block;

  .header-logo {
    width: 210px;
    margin-bottom: 30px;
  }
  .airline-header {
    display: table;
    margin-bottom: 10px;
  }
  .left-header {
    text-align: right;
    .export-date {
      display: block;
      margin-top: 5px;
      padding-right: 0px;
      color: #707070;
      line-height: 25px;
    }
    .export-time {
      display: block;
      line-height: normal;
      margin-top: -5px;
      padding-right: 0px;
      color: #707070;
    }
  }
  .item-div {
    border-bottom: 1px dashed #707070;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
  .last-item-div {
    border-bottom: 0;
  }
  .airline-logo {
    display: table-cell;
    vertical-align: middle;
    img {
      width: 40px;
      display: table-cell;
      vertical-align: middle;
    }
  }
  .airline-title {
    font-size: 16px;
    color: #231f20;
    font-family: "Roboto", Myanmar3 !important;
    font-weight: 400;
    line-height: 20px;
    display: table-cell;
    vertical-align: middle;
  }
  .airport-name {
    display: inline-block;
    width: 100%;
    line-height: 15px;
  }
  .via {
    display: inline-block;
    width: 100%;
    text-align: center;
    line-height: 15px;
  }
  .duration {
    display: block;
    text-align: center;
    line-height: 15px;
    margin: -5px;
  }
  .flight-info {
    border: 1px solid #707070;
    margin-bottom: 10px;
    .MuiGrid-item {
      border-right: 1px solid #707070;
    }
    span {
      padding-left: 10px;
      color: #707070;
    }
  }
  .fare-prices {
    .fare-name {
      color: #4e4e4e;
      line-height: 15px;
      text-transform: uppercase;
      display: inline-block;
      width: 100%;
    }
    .amount {
      color: #47afd4;
      line-height: 15px;
      font-size: 15px;
      font-weight: 500;
      display: block;
      margin-bottom: 10px;
    }
  }
  table {
    width: 100%;
    border: 1px solid #707070;
    border-collapse: collapse;
    th {
      border: 1px solid #707070;
      padding: 10px;
      font-size: 15px;
    }
    td {
      border: 1px solid #707070;
      padding: 5px;
      font-size: 14px;
    }
  }
  .note {
    color: #ffa52b;
    font-size: 13px;
  }
}
/****** end export pdf ********/

.info-child {
  color: #f7911e;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

/************* End product v3 new css ***************/

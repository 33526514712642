.button {
  background-color: #71d8db;
  color: white;
  font-family: "Poppins", sans-serif;
  border: none;
  width: 100%;
  height: 35px;
  margin-top: 12px;
  &:hover {
    cursor: pointer;
  }
}

.container {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 4px;
  padding: 20px 25px;
}

.firstRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.secondRow {
  margin-top: 40px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.iconBtn {
  position: absolute;
  background-color: transparent;
  border: none;
  left: 21.5%;
  top: 45%;
}

.nav {
  display: flex;
  padding: 13px;
  justify-content: space-between;
  align-items: center;
  .img {
    height: 65px;
  }
  .flymyaLogo {
    width: 140px;
  }
}

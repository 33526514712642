@mixin popperHeader {
  padding-bottom: 20px;
  border-bottom: 1px solid #e1e2e2;
}

@mixin pillContainer {
  background-color: $blue;
  color: #fff;
  padding: 8px;
  font-size: 0.9em;
  border-radius: 15px;
}

@mixin headText {
  font-weight: bold;
  margin-left: 12px;
  color: $dark;
}

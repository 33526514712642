.container {
  display: flex;
  flex-direction: column;
  width: 167px;
  @media (max-width: 941px) {
    width: 100%;
    margin-top: 20px;
  }

  label {
    font-size: 14px;
    font-weight: 300;
    @media (max-width: 941px) {
      font-size: 16px;
      font-weight: 400;
    }
  }
  input {
    border: 1px solid #e6e7e8;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    margin-top: 4px;
  }

  p {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
}

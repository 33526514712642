.fareContainer {
  display: flex;
  padding: 12px;
  flex-direction: column;
  width: 158px;
  height: 158px;
  color: #fff;
  border-radius: 5px;
  background-image: linear-gradient(#0e99eb, #d9f0f3 200%);
  margin-right: 12px;
  margin-bottom: 12px;
}

.packageNamePriceContainer {
  flex: 4;
  .packageName {
    font-size: 16px;
    font-weight: 500;
  }
  .priceText {
    font-size: 15px;
    font-weight: 700;
    margin-top: 5px;
  }
}

.nonRefundableTextContainer {
  flex: 1;
  .nonRefundableText {
    font-size: 14px;
  }
}

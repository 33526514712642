@import "../../../common/scssStyles/variables/colors";

.container {
  @media (max-width: 780px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.imageTextContainer {
  display: flex;
  margin-top: 20px;
}

.textContainer {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 10px;
    color: #fff;
  }
  a {
    text-decoration: none;
    color: #fff;
    margin-top: 5px;
    &:hover {
      color: $blue;
    }
  }
}

.container {
  border: 1px dashed black;
  margin-top: 20px;
  border-radius: 12px;
  padding: 18px 12px;

  p {
    text-align: center;
    line-height: 34px;
    font-size: 15px;
  }
  span {
    font-weight: 500;
    color: #00aeef;
    margin: 0 3px;
  }
}

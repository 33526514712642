@import "../../../common/scssStyles/variables/colors";
@import "../scssStyles/popperDesktop/mixin";

.popperPaper {
  background-color: #fff !important;
}

.container {
  padding: 20px;
}

.popperHeader {
  @include popperHeader;

  .pillContainer {
    @include pillContainer;
  }

  .headText {
    @include headText;
  }
}

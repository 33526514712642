@import "../../../common/scssStyles/variables/colors";

.container {
  width: 100%;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
}

.labelText {
  font-size: 1em;
  font-weight: 100;
  color: $grey;
}

.valueText {
  font-size: 1.3em;
  color: $dark;
  font-weight: bold;
}

.disable {
  opacity: 0.3;
  .valueText {
    font-weight: 100 !important;
  }
}

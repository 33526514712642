@import "../../../common/scssStyles/variables/colors";

.card {
  background-color: white;
  width: 93%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-bottom: 10px;
  border-radius: 6px;
  margin: 20px auto 0 auto;
}

.container {
  border: 1px solid #e4e4e4;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
  padding: 18px 20px;

  @media (max-width: 740px) {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  &:hover {
    border: 1px solid #00aeef;
  }
}

.clickDetectContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.logoInputContainer {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.container {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bodyContainer {
  background-color: #fff;
  padding-bottom: 40px;

  hr {
    border: 1px solid #e4e4e4;
  }
}

.btnContainer {
  width: 350px;
  margin: 30px auto 0 auto;
  display: flex;
  justify-content: center;
  button {
    height: 50px;
    width: 100%;
    background-color: #0d87dd;
    font-size: 17px;
    border-radius: 8px;
    color: white;
    border: none;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
}

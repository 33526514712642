@import "../../../common/scssStyles/variables/colors";

.tabContainer {
  display: flex;
}

.tab {
  flex-grow: 1;
  border: none;
  display: flex;
  justify-content: center;
  padding: 18px 0;
  background-color: $lightGrey;
  span {
    color: $dark;
    opacity: 0.2;
  }
}

.tabActive {
  background-color: white;
  border-bottom: 2px solid $blue;
  span {
    opacity: 1;
    color: $blue;
    font-weight: bold;
  }
}

.btn {
  background-color: #00aeef;
  padding: 8px 24px;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  @media (max-width: 941px) {
    width: 100%;
    margin-top: 20px;
  }
}

.packageContainer {
  display: flex;
  margin-right: 8px;
}

.leftSideContainer {
  width: 158px;
  height: 180px;
  border: 1px solid #e6e7e8;
  border-radius: 5px;
  padding: 8px;
}

.packageNameRefundTextContainer {
  padding-bottom: 3px;
  border-bottom: 1px solid #e6e7e8;
  .packageNameText {
    font-size: 16px;
    font-weight: 500;
  }
}

.arrowFarePriceContainer {
  border-bottom: 1px solid #e6e7e8;
  padding: 7px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .farePrice {
    font-size: 15px;
    font-weight: 700;
  }
  .iconButton {
    background-color: transparent;
    border: none;
  }
  .arrowIcon {
    color: #f7911e;
  }
}

.ageTypePriceText {
  font-size: 13px;
  font-weight: 300;
  margin-top: 3px;
}

.smallText {
  color: #42aeef;
  font-size: 12px;
  margin-top: 3px;
  font-weight: 300;
}

.rightSideContainer {
  margin-left: 4px;
  div {
    margin-bottom: 10px;
  }
  .priceText {
    font-size: 13px;
  }
}

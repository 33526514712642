.container {
  padding: 12px 4px;
  width: 240px;
  top: 30px;
  position: absolute;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 1;
  border-radius: 4px;
}

.checkBoxTextContainer {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.fareContainer {
  display: flex;
  padding: 12px;
  flex-direction: column;
  width: 158px;
  height: 158px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-right: 12px;
  margin-bottom: 12px;
}

.packageNameNonRefundTextContainer {
  flex-grow: 2;
  .packageNameText {
    color: #231f20;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .nonRefundText {
    color: #777;
    font-weight: 400;
    font-size: 12px;
    margin-top: 7px;
  }
}

.priceContainer {
  flex-grow: 1;
  .price {
    color: #f7911e;
    font-size: 15px;
    font-weight: 700;
  }
}

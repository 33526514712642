.selectEditBtnContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 780px) {
    margin-top: 20px;
  }
}

.dropDownContainer {
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
  }
  .selectBox {
    margin-left: 12px;
    height: 40px !important;
    font-size: 15px !important;
    @media (max-width: 780px) {
      height: 24px !important;
      font-size: 13px !important;
      margin-left: 5px;
    }
  }
}

.editBtn {
  background-color: transparent;
  color: #f7911e;
  border: 1px solid #f7911e;
  padding: 14px;
  font-size: 17px;
  border-radius: 7px;
  transition: all linear 0.2s;
  &:hover {
    color: white;
    cursor: pointer;
    background-color: #f7911e;
  }
  @media (max-width: 780px) {
    padding: 10px;
    font-size: 14px;
  }
}

.container {
  text-align: center;
  margin-top: 17px;

  .headerText {
    color: #231f20;
    font-size: 32px;
    font-weight: 300;
  }
  .subHeaderText {
    margin-top: 8px;
    font-weight: 400;
    color: #8d8d8d;
    font-size: 18px;
  }
  button {
    margin-top: 12px;
    background-color: #00aeef;
    color: white;
    border: none;
    width: 140px;
    padding: 10px;
    border-radius: 4px;
    font-size: 16px;
    &:hover {
      cursor: pointer;
    }
  }
}

.container {
  flex-grow: 3;

  @media (max-width: 868px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.destText {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;

  @media (max-width: 780px) {
    font-size: 13px;
  }

  .arrowIcon {
    margin: 0 20px;
    @media (max-width: 780px) {
      font-size: 30px !important;
      margin: 0 0px;
    }
  }
}

.dateTypePassText {
  margin-top: 6px;
  color: #7f91a8 !important;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  @media (max-width: 780px) {
    font-size: 13px;
    margin-top: 3px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 2.3;
  }
  .dotIcon {
    margin: 0 7px;
    font-size: 7px !important;
  }
}

.btnsContainer {
  margin-top: 35px;
  width: 50%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 722px) {
    width: 80%;
  }

  @media (max-width: 459px) {
    span {
      display: none;
    }
  }

  button {
    background-color: transparent;
    color: #1ba0e2;
    border: none;
    font-size: 14px;
    padding-bottom: 12px;

    &:hover {
      cursor: pointer;
    }
  }
}

.activeTabBtn {
  border-bottom: 3px solid #1ba0e2 !important;
}
